import React from 'react';
import { Link } from 'react-router-dom';
import './TeachOneCongrats.scss';
import NavMenu from '../shared/NavMenu.js';
import Sidebar from '../shared/Sidebar.js';
import ShareIcon from './TeachOne_Share.png';
import QuizIcon from './TeachOne_Quiz.png';
import MoreResourcesIcon from './MoreResources.png';
import QandADoc from './QandA.docx';
import MobileNav from '../shared/MobileNav.js';
import ScrollToTopOnMount from '../shared/ScrollToTopOnMount.js';
import { getMobileOperatingSystem, ANDROID_HREF, IOS_HREF, EMAIL_HREF } from '../shared/utils.js';
//import ReactGA from 'react-ga';


class TeachOneCongrats extends React.Component {

  componentDidMount() {
    //ReactGA.pageview("/teachone/congrats")
  }

  render () {
    const isAndroid = (getMobileOperatingSystem() === "Android")

    return (
      <div id="TeachOneCongrats">
        <Sidebar stage="TeachOne" section="congrats"/>
        <ScrollToTopOnMount />
        <NavMenu showHome={this.props.showHome} showSeeOne={true} showDoOne={true} showTeachOne={true} />
        <MobileNav />
        <div className="teachCongratsContent" >
          <h2>Congratulations, you've completed this Abortion Pill training!</h2>
          <div className="teachLinks" >
            <div className="teachLink">
              <a href={QandADoc} download>
                <img src={QuizIcon} alt="Quiz" />
                <p >Download</p>
                <p className="nextRow" >Q &amp; A</p>
              </a>
            </div>
            <Link to="/teachone/more" className="teachLink">
              <img src={MoreResourcesIcon} alt="More Resources" id="moreResourcesIcon"/>
              <p className="narrow">Ordering pills</p>
              <p className="narrow nextRow">and more</p>
              <p className="narrow nextRow">resources</p>
            </Link>
            <div className="teachLink">
              { isAndroid ? 
              <a href={ANDROID_HREF} className="teachLink mobileOnly">
                <img src={ShareIcon} className="mobileOnly" alt="share"/>
                <p className="mobileOnly">Share</p>
              </a> :
              <a href={IOS_HREF} className="teachLink mobileOnly">
                <img src={ShareIcon} className="mobileOnly" alt="share"/>
                <p className="mobileOnly">Share</p>
              </a> 
              }
              <a href={EMAIL_HREF} className="teachLink desktopOnly">
                <img src={ShareIcon} alt="share" className="desktopOnly"/>
                <p className="teachlinkText desktopOnly">Share</p>
              </a>
            </div>
          </div>
          <div className="cmeContainer">
            <div className="claimCME">
              <a href="https://cmecalifornia.com/lms/activity?@curriculum.id=-1&@activity.id=7789707&@activity.bundleActivityId=-1">
                <span>Claim CME Credit</span>
                <div className="arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeachOneCongrats;
