import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom';
import './MobileNav.scss';
import { CONTACT_HREF } from '../shared/utils.js';

function MobileNav(props) {
  return (
    <div className="MobileNav">
      <Menu>
        <Link to="/seeone" className="menu-item">
          See?
        </Link>
         <Link to="/doone" className="menu-item">
          Do!
        </Link>
        <Link to="/teachone" className="menu-item">
          Teach!
        </Link>
        <Link to="/" className="menu-item">
          About
        </Link>
        <a href="https://cmecalifornia.com/lms/activity?@curriculum.id=-1&@activity.id=7789707&@activity.bundleActivityId=-1" className="menu-item">CME</a>
        <a href={CONTACT_HREF} className="menu-item">Feedback</a>
      </Menu>
      <div className="title">
      Abortion Pill CME
      </div>
      <div className="border">
      </div>
    </div>
  );
}

export default MobileNav;
