import React from 'react';
import './TeachOneMoreResources.scss';
import NavMenu from '../shared/NavMenu.js';
import Sidebar from '../shared/Sidebar.js';
import MobileNav from '../shared/MobileNav.js';
import ScrollToTopOnMount from '../shared/ScrollToTopOnMount.js';
//import ReactGA from 'react-ga';

class TeachOneMoreResources extends React.Component {

  componentDidMount() {
    //ReactGA.pageview("/teachone/more")
  }

  render () {
    return (
      <div id="TeachOneMoreResources">
        <Sidebar stage="TeachOne" section="more" />
        <ScrollToTopOnMount />
        <NavMenu showHome={this.props.showHome} showSeeOne={true} showDoOne={true} showTeachOne={true} />
        <MobileNav />
        <div className="teachMoreContent" >
          <h2>More Resources</h2>
          <a href="https://www.reproductiveaccess.org/resource/order-mifepristone/" target="_blank" rel="noopener noreferrer">
          How to Order
          </a>
          <a href="/POC.png" target="_blank" rel="noopener noreferrer">
          What does early pregnancy tissue look like?
          </a>
          <a href="https://www.reproductiveaccess.org/resources/?rsearch=&rtopic%5B%5D=42" target="_blank" rel="noopener noreferrer">
          Reproductive Health Access Project (RHAP)
          </a>
          <a href="https://www.teachtraining.org/training-tools/officepracticetools/" target="_blank" rel="noopener noreferrer">
          Training in Early Abortion for Comprehensive Healthcare (TEACH)
          </a>
          <div className="cmeContainer">
            <div className="claimCME">
              <a href="https://cmecalifornia.com/lms/activity?@curriculum.id=-1&@activity.id=7789707&@activity.bundleActivityId=-1">
                <span>Claim CME Credit</span>
                <div className="arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeachOneMoreResources;
